import { createFileRoute, Link } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  component: Index,
});

function Index() {
  return (
    <div className="flex items-center justify-center p-2 pt-14 text-center">
      <div className="prose prose-sm prose-neutral flex flex-col">
        <h1>Sparks Experiments</h1>
        <Link
          to="/experiments/$experimentId"
          params={{ experimentId: "magic-trail" }}
          className="[&.active]:font-bold"
        >
          Magic Trail
        </Link>

        <Link
          to="/experiments/$experimentId"
          params={{ experimentId: "boxes" }}
          className="[&.active]:font-bold"
        >
          Boxes
        </Link>
        <Link
          to="/experiments/$experimentId"
          params={{ experimentId: "boxes-physics" }}
          className="[&.active]:font-bold"
        >
          Boxes (Physics)
        </Link>
        <Link
          to="/experiments/$experimentId"
          params={{ experimentId: "sound-slider" }}
          className="[&.active]:font-bold"
        >
          Sound Slider
        </Link>
        <Link
          to="/experiments/$experimentId"
          params={{ experimentId: "pointer" }}
          className="[&.active]:font-bold"
        >
          Pointer
        </Link>
        <Link
          to="/experiments/$experimentId"
          params={{ experimentId: "leva" }}
          className="[&.active]:font-bold"
        >
          Leva
        </Link>
      </div>
    </div>
  );
}
